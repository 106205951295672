import { ContractDeployed, ContractDeployInfo } from 'functions/includes/contract'
import { getWallet } from 'src/services/wallet.service'
import { Web3Provider } from '@ethersproject/providers'
import { BigNumber, ContractFactory, utils } from 'ethers'
import React, { useEffect, useState } from 'react'
import { postDeployed } from 'src/services/api.service'

export const DeployContract
  : React.FC<{ gasPrice: BigNumber, contractInfo: ContractDeployInfo & Partial<ContractDeployed> }>
  = ({ contractInfo, gasPrice }) => {

  const {
    contractName,
    name,
    symbol,
    baseURI,
    supplyLimit,
    onceLimit,
    maxLimit,
    preMintTime,
    publicMintTime,
    address
  } = contractInfo
  const [ deployed, setDeployed ] = useState(false)
  const [ contractAddress, setContractAddress ] = useState('')
  const [ estimateGas, setEstimateGas ] = useState(BigNumber.from(56317))

  const getContractFactory = () => {
    const wallet = getWallet()
    const provider = new Web3Provider(wallet)
    const signer = provider.getSigner()

    const artifact = require(`./resources/${ contractName }.json`)
    return new ContractFactory(artifact.abi, artifact.bytecode, signer)
  }

  const deployContract = async () => {
    const factory = getContractFactory()

    setTimeout(async () => {
      const contract = await factory.deploy(name, symbol, baseURI, supplyLimit, onceLimit, maxLimit, preMintTime, publicMintTime)
      await postDeployed(contract.deployTransaction.from, {
        txHash: contract.deployTransaction.hash,
        address: contract.address,
        deployed: false,
        allowList: {}
      })
      setContractAddress(contract.address)
      await contract.deployed()
      await postDeployed(contract.deployTransaction.from, {
        txHash: contract.deployTransaction.hash,
        address: contract.address,
        deployed: true,
        allowList: {}
      })
      setDeployed(true)
      window.location.reload()
    }, 500)
  }

  useEffect(() => {
    if (!contractName) return

    const factory = getContractFactory()
    const data = factory.interface.encodeDeploy([ name, symbol, baseURI, supplyLimit, onceLimit, maxLimit, preMintTime, publicMintTime ])
    setTimeout(async () => {
      const estGas = await factory.signer.estimateGas({ data })
      setEstimateGas(estGas)
    })
  }, [ contractName, gasPrice ])

  useEffect(() => {
    if (address) {
      setContractAddress(address)
      setDeployed(true)
    }
  }, [ address ])

  return (
    <>
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-6 md:gap-6">
          <div className="md:col-span-2">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-white">컨트랙트 정보</h3>
              <p className="mt-1 text-sm text-white">
                ERC721 배포 정보입니다
              </p>
              <p className={ 'pt-4 text-sm text-white' }>
                gas limit: { Intl.NumberFormat().format(estimateGas.toNumber()) }
              </p>
              <p className={ 'pt-2 text-sm text-white' }>
                가스비 예상:
                <br />
                { utils.formatEther(estimateGas.mul(gasPrice)) } ETH
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-3 md:mt-0">
            <form action="#" method="POST">
              <div className="overflow-hidden shadow sm:rounded-md">
                <div className="bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-2">
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                        컨트랙트 이름
                      </label>
                      <input className={ 'block rounded-md w-full' } type="text" id="name" name="name" value={ name }
                             readOnly={ true } />
                    </div>

                    <div className="col-span-1">
                      <label htmlFor="symbol" className="block text-sm font-medium text-gray-700">
                        심볼
                      </label>
                      <input className={ 'block rounded-md w-full' } type="text" id="symbol" name="symbol"
                             value={ symbol } readOnly={ true } />
                    </div>

                    <div className="col-span-6 sm:col-span-6">
                      <label htmlFor="baseURI" className="block text-sm font-medium text-gray-700">
                        메타데이터 baseURI
                      </label>
                      <input className={ 'block rounded-md w-full' } type="text" id="baseURI" name="baseURI"
                             value={ baseURI } readOnly={ true } />
                      <p className={ 'px-2 py-1 text-xs' }>민팅 후 NFT tokenURI(1) 예시: <a className={ 'underline' }
                                                                                       target={ '_blank' }
                                                                                       rel={ 'noreferrer' }
                                                                                       href={ `https://ipfs.io/ipfs/${ baseURI.substring(7) }/1.json` }>{ baseURI }/1.json</a>
                      </p>
                    </div>

                    <div className="col-span-2 sm:col-span-2">
                      <label htmlFor="supplyLimit" className="block text-sm font-medium text-gray-700">
                        총 제공량
                      </label>
                      <input className={ 'block rounded-md w-full' } type="number" id="supplyLimit" name="supplyLimit"
                             value={ supplyLimit } readOnly={ true } />
                    </div>

                    <div className="col-span-2 sm:col-span-2">
                      <label htmlFor="onceLimit" className="block text-sm font-medium text-gray-700">
                        퍼블릭 1회 민팅 수량
                      </label>
                      <input className={ 'block rounded-md w-full' } type="number" id="onceLimit" name="onceLimit"
                             value={ onceLimit } readOnly={ true } />
                    </div>

                    <div className="col-span-2 sm:col-span-2">
                      <label htmlFor="maxLimit" className="block text-sm font-medium text-gray-700">
                        퍼블릭 최대 민팅 수량
                      </label>
                      <input className={ 'block rounded-md w-full' } type="number" id="maxLimit" name="maxLimit"
                             value={ maxLimit } readOnly={ true } />
                    </div>

                    <div className="col-span-3 sm:col-span-3">
                      <label htmlFor="preMintTime[0]" className="block text-sm font-medium text-gray-700">
                        허용리스트 민팅 시작시간
                      </label>
                      <input className={ 'block rounded-md w-full' } type="text" id="preMintTime[0]"
                             name="preMintTime[0]" defaultValue={ preMintTime[0] } readOnly={ true } />
                      <p className={ 'px-2 py-1 text-xs' }>{ (new Date(preMintTime[0] * 1000)).toString() }</p>
                    </div>

                    <div className="col-span-3 sm:col-span-3">
                      <label htmlFor="preMintTime[1]" className="block text-sm font-medium text-gray-700">
                        허용리스트 민팅 종료시간
                      </label>
                      <input className={ 'block rounded-md w-full' } type="text" id="preMintTime[1]"
                             name="preMintTime[1]" defaultValue={ preMintTime[1] } readOnly={ true } />
                      <p className={ 'px-2 py-1 text-xs' }>{ (new Date(preMintTime[1] * 1000)).toString() }</p>
                    </div>

                    <div className="col-span-3 sm:col-span-3">
                      <label htmlFor="publicMintTime[0]" className="block text-sm font-medium text-gray-700">
                        퍼블릭 민팅 시작시간
                      </label>
                      <input className={ 'block rounded-md w-full' } type="text" id="publicMintTime[0]"
                             name="publicMintTime[0]" defaultValue={ publicMintTime[0] } readOnly={ true } />
                      <p className={ 'px-2 py-1 text-xs' }>{ (new Date(publicMintTime[0] * 1000)).toString() }</p>
                    </div>

                    <div className="col-span-3 sm:col-span-3">
                      <label htmlFor="preMintTime[1]" className="block text-sm font-medium text-gray-700">
                        퍼블릭 민팅 종료시간
                      </label>
                      <input className={ 'block rounded-md w-full' } type="text" id="publicMintTime[1]"
                             name="publicMintTime[1]" defaultValue={ publicMintTime[1] } readOnly={ true } />
                      <p className={ 'px-2 py-1 text-xs' }>{ (new Date(publicMintTime[1] * 1000)).toString() }</p>
                    </div>

                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  { deployed ? (<p className="inline-flex px-4">컨트랙트 주소: { contractAddress }</p>) : null }
                  <button
                    type="button"
                    onClick={ deployContract }
                    disabled={ deployed || contractAddress.length > 0 }
                    className={ (deployed ? "bg-green-600" : ((contractAddress.length > 0) ? "bg-gray-700" : "bg-indigo-600 hover:bg-indigo-700")) + " inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" }
                  >
                    { deployed ? '배포 완료 ✔' : ((contractAddress.length > 0) ? '배포 중 🧾' : '컨트랙트 배포') }
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
