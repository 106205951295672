import { getWallet } from 'src/services/wallet.service'
import { Web3Provider } from '@ethersproject/providers'
import React, { useEffect, useState } from 'react'
import { BigNumber, Contract, utils } from 'ethers'
import { ContractDeployed, ContractDeployInfo } from 'functions/includes/contract'
import { SubmitButton } from 'src/SubmitButton'

export const SetPreMintTime
  : React.FC<{ gasPrice: BigNumber, contractInfo: ContractDeployInfo & Partial<ContractDeployed> }>
  = ({ contractInfo, gasPrice }) => {

  const [ status, setStatus ] = useState<{ [key: number]: 'none' | 'pending' | 'finished' }>({
    0: 'none',
    1: 'none',
  })
  const { contractName, txHash, address, deployed, preMintTime } = contractInfo
  const [ values, setValues ] = useState({
    start: preMintTime[0] || 0,
    end: preMintTime[1] || 0
  })
  const [ estimateGas, setEstimateGas ] = useState({
    0: BigNumber.from(0),
  })

  const updateStatus = (statusName: 'none' | 'pending' | 'finished', index: number) => {
    setStatus(status => {
      return {
        ...status,
        [index]: statusName
      }
    })
  }

  const getContract = (contractName: string, address: string) => {
    const wallet = getWallet()
    const provider = new Web3Provider(wallet)
    const signer = provider.getSigner()

    const artifact = require(`./resources/${ contractName }.json`)
    return new Contract(address, artifact.abi, signer)
  }

  const submit = (index: number) => {
    if (!txHash || !address || !deployed) return

    const contract = getContract(contractName, address)

    if (index === 0)
      setTimeout(async () => {
        updateStatus('pending', index)
        try {
          const [start, end] = await contract.getPreMintTime()
          setValues({start: start.toNumber(), end: end.toNumber()})
        } catch (e: any) {
          alert(e.message)
        }
        updateStatus('none', index)
      })
    else if (index === 1)
      setTimeout(async () => {
        updateStatus('pending', index)
        try {
          const tx = await contract.setPreMintTime(values.start, values.end)
          await tx.wait()

          const [start, end] = await contract.getPreMintTime()
          setValues({start: start.toNumber(), end: end.toNumber()})
          updateStatus('finished', index)
        } catch (e: any) {
          updateStatus('none', index)
          alert(e.message)
        }
      })
  }

  const estimate = async (contract: Contract, start: number, end: number) => {
    const estGas = await contract.estimateGas.setPreMintTime(start, end)
    setEstimateGas({
        0: estGas
    })
  }

  useEffect(() => {
    if (!contractName || !address || !deployed) {
      setEstimateGas({
        0: BigNumber.from(34037),
      })
      return
    }

    const contract = getContract(contractName, address)
    setTimeout(async () => {
      await estimate(contract, values.start, values.end)
    })

  }, [ contractName, address, gasPrice ])

  useEffect(() => {
    if (preMintTime.length === 2) {
      if (address && deployed) {
        const contract = getContract(contractName, address)
        setTimeout(async () => {
          const [start, end] = await contract.getPreMintTime()
          setValues({start: start.toNumber(), end: end.toNumber()})
        })
      } else {
        setValues({start: preMintTime[0], end: preMintTime[1]})
      }
    }
  }, [preMintTime])

  const onChangeStart = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setValues({
      start: parseInt(value),
      end: values.end
    })
  }

  const onChangeEnd = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setValues(values => {
      return {
        start: values.start,
        end: parseInt(value),
      }
    })
  }

  const buttonTitle = (status: string, index: number) => {
    let title
    if (status === 'none')
      title = index === 0 ? '초기화' : '변경'
    else if (status === 'pending')
      title = index === 0 ? '초기화 중 🧾' : '변경 중 🧾'
    else
      title = '변경 완료 ✔'

    return title
  }

  return (
    <>
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-6 md:gap-6">
          <div className="md:col-span-2">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-white">프리 민팅(허용리스트) 시간</h3>
              <p className={ 'pt-4 text-sm text-white' }>
                gas limit: { Intl.NumberFormat().format(estimateGas[0].toNumber()) }
              </p>
              <p className={ 'pt-2 text-sm text-white' }>
                가스비 예상:
                <br />
                { utils.formatEther(estimateGas[0].mul(gasPrice)) } ETH
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-3 md:mt-0">
            <form action="#" method="POST">
              <div className="overflow-hidden shadow sm:rounded-md">
                <div className="bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-6 gap-6">

                    <div className="col-span-3 sm:col-span-3">
                      <label htmlFor="preMintTime[0]" className="block text-sm font-medium text-gray-700">
                        프리 민팅 시작시간
                      </label>
                      <input className={ 'block rounded-md w-full' } type="text" id="preMintTime[0]"
                             name="preMintTime[0]" value={ values.start } onChange={onChangeStart} />
                      <p className={ 'px-2 py-1 text-xs' }>{ (new Date(values.start * 1000)).toString() }</p>
                    </div>

                    <div className="col-span-3 sm:col-span-3">
                      <label htmlFor="preMintTime[1]" className="block text-sm font-medium text-gray-700">
                        프리 민팅 종료시간
                      </label>
                      <input className={ 'block rounded-md w-full' } type="text" id="preMintTime[1]"
                             name="preMintTime[1]" value={ values.end } onChange={onChangeEnd} />
                      <p className={ 'px-2 py-1 text-xs' }>{ (new Date(values.end * 1000)).toString() }</p>
                    </div>

                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6 space-x-2">
                  { deployed ?
                    (
                      <>
                        <SubmitButton status={ status } index={ 0 } setTitle={ buttonTitle } updateStatus={ submit } />
                        <SubmitButton status={ status } index={ 1 } setTitle={ buttonTitle } updateStatus={ submit } />
                      </>
                    ) : null
                  }
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
