import { getWallet } from 'src/services/wallet.service'
import { Web3Provider } from '@ethersproject/providers'
import React, { useEffect, useState } from 'react'
import { addresses, limits } from 'src/allowList'
import { BigNumber, Contract, utils } from 'ethers'
import { ContractDeployed, ContractDeployInfo } from 'functions/includes/contract'
import { postDeployed } from 'src/services/api.service'
import { SubmitButton } from 'src/SubmitButton'

export const SetAllowList
  : React.FC<{ gasPrice: BigNumber, contractInfo: ContractDeployInfo & Partial<ContractDeployed> }>
  = ({ contractInfo, gasPrice }) => {

  const [ status, setStatus ] = useState<{ [key: number]: 'none' | 'pending' | 'finished' }>({
    0: 'none',
    1: 'none',
    2: 'none',
    3: 'none',
    4: 'none',
  })
  const [ values, setValues ] = useState({
    address: '',
    amount: ''
  })
  const [ estimateGas, setEstimateGas ] = useState({
    0: BigNumber.from(0),
    1: BigNumber.from(0),
    2: BigNumber.from(0),
    3: BigNumber.from(0),
    4: BigNumber.from(0),
  })
  const [ valid, setValid ] = useState('주소 확인 중')

  const { contractName, txHash, address, deployed, allowList } = contractInfo

  let amount: number[] = [ 0, 0 ]
  for (const limit of limits) {
    if (limit === 1)
      amount[0] += 1
    else if (limit === 5)
      amount[1] += 1
    else
      throw new Error('invalid limit')
  }

  const partialUpdate = async (contract: Contract, start: number, end: number) => {
    const tx = await contract.setPreMintList(addresses.slice(start, end), limits.slice(start, end))
    await tx.wait()
  }

  const updateStatus = (statusName: 'none' | 'pending' | 'finished', index: number) => {
    setStatus(status => {
      return {
        ...status,
        [index]: statusName
      }
    })
  }

  const getContract = (contractName: string, address: string) => {
    const wallet = getWallet()
    const provider = new Web3Provider(wallet)
    const signer = provider.getSigner()

    const artifact = require(`./resources/${ contractName }.json`)
    return new Contract(address, artifact.abi, signer)
  }

  const submit = (index: number) => {
    if (!txHash || !address || !deployed) return

    const contract = getContract(contractName, address)

    setTimeout(async () => {
      updateStatus('pending', index)
      try {
        const start = index * 500 + 1
        let end = (index + 1) * 500
        if (end >= addresses.length)
          end = addresses.length

        await partialUpdate(contract, start, end)

        await postDeployed(await contract.signer.getAddress(), {
          txHash: txHash,
          address: address,
          deployed: deployed,
          allowList: { [index]: 500 }
        })

        updateStatus('finished', index)
      } catch (e: any) {
        updateStatus('none', index)
        alert(e.message)
      }
    })
  }

  const onChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const index = addresses.findIndex((x: string) => x === value)

    if (index < 0)
      setValues({
        address: value,
        amount: ''
      })
    else
      setValues({
        address: value,
        amount: limits[index]
      })
  }

  useEffect(() => {
    for (let key in allowList) {
      updateStatus('finished', parseInt(key))
    }
  }, [ allowList ])

  const estimate = async (contract: Contract, index: number) => {
    const start = index * 500 + 1
    let end = (index + 1) * 500
    if (end >= addresses.length)
      end = addresses.length

    const estGas = await contract.estimateGas.setPreMintList(addresses.slice(start, end), limits.slice(start, end))
    setEstimateGas(estimateGas => {
      return {
        ...estimateGas,
        [index]: estGas
      }
    })
  }

  useEffect(() => {
    if (!contractName || !address || !deployed) {
      setEstimateGas({
        0: BigNumber.from(24081879),
        1: BigNumber.from(24081915),
        2: BigNumber.from(24081915),
        3: BigNumber.from(24081927),
        4: BigNumber.from(22249421),
      })
      return
    }

    const contract = getContract(contractName, address)
    setTimeout(async () => {
      for (let i = 0; i < 5; i++) {
        await estimate(contract, i)
      }
    })

  }, [ contractName, address, gasPrice ])

  useEffect(() => {
    for (const _address of addresses) {
      if (!utils.isAddress(_address)) {
        setValid('잘못된 주소: ' + _address)
        return
      }
    }
    setValid('주소 유효성 확인: 완료')

  }, [])

  // useEffect(() => {
  //   if (allowListRegistered) setStatus('finished')
  // }, [allowListRegistered])

  const buttonTitle = (status: string, index: number) => {
    let title
    if (status === 'none')
      title = '등록'
    else if (status === 'pending')
      title = '등록 중 🧾'
    else
      title = '등록 완료 ✔'

    if (index === 0)
      title += ` ~ 500`
    else if (index === 1)
      title += ` ~ 1000`
    else if (index === 2)
      title += ` ~ 1500`
    else if (index === 3)
      title += ` ~ 2000`
    else
      title += ` ~ ${ addresses.length }`

    return title
  }

  return (
    <>
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-6 md:gap-6">
          <div className="md:col-span-2">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-white">허용 리스트 정보</h3>
              <p className={ 'pt-4 text-sm text-white' }>
                gas limit: { Intl.NumberFormat().format(estimateGas[0].toNumber()) }
                <br />
                gas limit: { Intl.NumberFormat().format(estimateGas[1].toNumber()) }
                <br />
                gas limit: { Intl.NumberFormat().format(estimateGas[2].toNumber()) }
                <br />
                gas limit: { Intl.NumberFormat().format(estimateGas[3].toNumber()) }
                <br />
                gas limit: { Intl.NumberFormat().format(estimateGas[4].toNumber()) }
              </p>
              <p className={ 'pt-2 text-sm text-white' }>
                가스비 예상:
                <br />
                { utils.formatEther(estimateGas[0].mul(gasPrice)) } ETH
                <br />
                { utils.formatEther(estimateGas[1].mul(gasPrice)) } ETH
                <br />
                { utils.formatEther(estimateGas[2].mul(gasPrice)) } ETH
                <br />
                { utils.formatEther(estimateGas[3].mul(gasPrice)) } ETH
                <br />
                { utils.formatEther(estimateGas[4].mul(gasPrice)) } ETH
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-3 md:mt-0">
            <form action="#" method="POST">
              <div className="overflow-hidden shadow sm:rounded-md">
                <div className="bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-6 gap-6">

                    <div className="col-span-5">
                      <label htmlFor="checkAddress" className="block text-sm font-medium text-gray-700">
                        주소 확인
                      </label>
                      <input className={ 'block rounded-md w-full' } type="text" id="checkAddress" name="checkAddress"
                             value={ values.address } onChange={ onChangeAddress } />
                    </div>

                    <div className="col-span-1">
                      <label htmlFor="checkAmount" className="block text-sm font-medium text-gray-700">
                        제한 수량
                      </label>
                      <input className={ 'block rounded-md w-full' } type="text" id="checkAmount" name="checkAmount"
                             value={ values.amount } readOnly={ true } />
                    </div>

                    <div className="col-span-1">
                      <label htmlFor="addressCount" className="block text-xs font-medium text-gray-700">
                        총 주소 갯수
                      </label>
                      <input className={ 'block rounded-md w-full' } type="text" id="addressCount" name="addressCount"
                             value={ addresses.length } readOnly={ true } />
                    </div>

                    <div className="col-span-1">
                      <label htmlFor="amount[0]" className="block text-xs font-medium text-gray-700">
                        1개 제한 주소
                      </label>
                      <input className={ 'block rounded-md w-full' } type="text" id="amount[0]" name="amount[0]"
                             value={ amount[0] } readOnly={ true } />
                    </div>

                    <div className="col-span-1">
                      <label htmlFor="amount[1]" className="block text-xs font-medium text-gray-700">
                        5개 제한 주소
                      </label>
                      <input className={ 'block rounded-md w-full' } type="text" id="amount[1]" name="amount[1]"
                             value={ amount[1] } readOnly={ true } />
                    </div>

                    <div className="col-span-3 flex justify-center items-center">
                      <p className="px-4">{ valid }</p>
                    </div>

                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6 space-x-2">
                  { deployed ?
                    (
                      <>
                        <SubmitButton status={ status } index={ 0 } setTitle={ buttonTitle } updateStatus={ submit } />
                        <SubmitButton status={ status } index={ 1 } setTitle={ buttonTitle } updateStatus={ submit } />
                        <SubmitButton status={ status } index={ 2 } setTitle={ buttonTitle } updateStatus={ submit } />
                        <SubmitButton status={ status } index={ 3 } setTitle={ buttonTitle } updateStatus={ submit } />
                        <SubmitButton status={ status } index={ 4 } setTitle={ buttonTitle } updateStatus={ submit } />
                      </>
                    ) : null
                  }
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
