import { Auth } from 'functions/includes/auth'
import { ContractDeployed } from 'functions/includes/contract'

export const login = async (auth: Auth) => {
  const authData = JSON.stringify(auth)
  const response = await fetch('/login', {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: authData
  })

  if (response.ok) {
    sessionStorage.setItem('auth', authData)
    return true
  }

  return false
}

export const logout = () => {
  sessionStorage.removeItem('auth')
}

export const getAuth = () => {
  const authData = sessionStorage.getItem('auth')
  if (authData) return JSON.parse(authData)
  return null
}

export const postDeployed = async (user:string, deployedInfo: ContractDeployed) => {
  const response = await fetch('/deployed', {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({ user, contract: deployedInfo })
  })

  return response.ok
}