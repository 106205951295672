import React, { useEffect, useState } from 'react'
import './App.css';
import { ContractDeployed, ContractDeployInfo } from 'functions/includes/contract'
import { DeployContract } from 'src/DeployContract'
import { SetAllowList } from 'src/SetAllowList'
import { getWallet } from 'src/services/wallet.service'
import { Web3Provider } from '@ethersproject/providers'
import { logout } from 'src/services/api.service'
import { BigNumber, utils } from 'ethers'
import { SetPublicMintTime } from 'src/SetPublicMintTime'
import { SetPreMintTime } from 'src/SetPreMintTime'

function App() {
  const [contractInfo, setContractInfo] = useState<ContractDeployInfo>({
    contractName: '',
    name: '',
    symbol: '',
    baseURI: '',
    supplyLimit: 0,
    onceLimit: 0,
    maxLimit: 0,
    preMintTime: [],
    publicMintTime: []
  })
  const [network, setNetwork] = useState('')
  const [gasWei, setGasWei] = useState(BigNumber.from(0))

  const updateNetwork = (chainId: string|number) => {
    switch (chainId) {
      case '0x1': setNetwork('메인넷'); break
      default: setNetwork(`테스트넷 (${chainId})`); break
    }
  }

  let init = false
  useEffect(() => {
    if (init) return
    init = true

    setTimeout(async () => {
      const wallet = getWallet()
      const provider = new Web3Provider(wallet)
      const signer = provider.getSigner()

      updateNetwork(`0x${(await provider.getNetwork()).chainId}`)
      wallet.on('chainChanged', (chainId: string) => {
        updateNetwork(chainId)
      });

      setInterval(async () => {
        const gasWei = await provider.getGasPrice()
        setGasWei(gasWei)
      }, 5000)

      try {
        const address = await signer.getAddress()
        if (!address) {
          logout()
          return
        }

        const data = await fetch('/info', {
          method: 'POST',
          body: JSON.stringify({user: address})
        })
        const info = await data.json() as ContractDeployInfo & Partial<ContractDeployed>
        setContractInfo(info)
      } catch (e) {
        logout()
        return
      }
    })
  }, [])

  return (
    <div className="bg-aaa-background">
      <div className={'container flex flex-col justify-center items-center'}>
        <div className={'py-20 text-center text-white'}>
          <h1 className={'text-3xl'}>fanCPlace x HIPHOP Reboot 민팅 관리페이지</h1>
        </div>
        <div className={'space-y-10'}>
          {network ? (
            <div className={'text-center pb-8 text-white '}>
              <p className={''}>연결된 네트워크: [{network}]</p>
              <p className={''}>
                {gasWei.gt(0)
                  ? `현재 가스 가격: ${utils.formatUnits(gasWei, 'gwei')} gwei (${utils.formatEther(gasWei)} ETH)`
                  : '현재 가스 가격: 조회중'
                }
              </p>
            </div>
          ) : null}
          <DeployContract contractInfo={contractInfo} gasPrice={gasWei} />
          <SetAllowList contractInfo={contractInfo} gasPrice={gasWei} />
          <SetPreMintTime gasPrice={gasWei} contractInfo={contractInfo} />
          <SetPublicMintTime gasPrice={gasWei} contractInfo={contractInfo} />
        </div>
        <div className={'py-20 text-center text-white'}>
          <h1 className={'text-2xl'}><span className={'text-lg'}>Made by</span> DARKO Studio&reg;</h1>
          <address className={'py-2 text-left not-italic font-ubuntu font-thin'}>
            help@darko.studio
          </address>
        </div>
      </div>
    </div>
  );
}

export default App;
